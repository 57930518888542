import React from "react";

import Layout from "../../components/Layout";


const MobilePage = () => (
  <Layout>
    {/*<ResearchInfo />*/}
  </Layout>
);

export default MobilePage;
